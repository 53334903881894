import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Power Clean 4×4\\@75%1rm`}</p>
    <p>{`+`}{` Front Squat 4×4@ same weight`}</p>
    <p>{`then,`}</p>
    <p>{`200m run`}</p>
    <p>{`20-power cleans (75/55)(rx+ 95/65)`}</p>
    <p>{`20-front squats`}</p>
    <p>{`400m run`}</p>
    <p>{`15-power cleans (105/70) (rx+ 135/95)`}</p>
    <p>{`15-front squats`}</p>
    <p>{`600m run`}</p>
    <p>{`10-power cleans (135/95)(rx+ 185/125)`}</p>
    <p>{`10-front squats`}</p>
    <p>{`for time.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      